import React, {useEffect, useRef, useState} from "react";
import { graphql } from "gatsby";
import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";

import parse from 'html-react-parser';

import Layout from "../../components/layout";
import Seo from "../../components/seo";

//section
import TribeticHeroSection from "../sections/caseStudies/Tribetic/TribeticHeroSection";
import ChallengesSection from "../sections/caseStudies/ChallengesSection";
import TribeticSection1 from "../sections/caseStudies/Tribetic/TribeticSection1";
import TribeticSection2 from "../sections/caseStudies/Tribetic/TribeticSection2";
import TribeticSection3 from "../sections/caseStudies/Tribetic/TribeticSection3";
import TribeticSection4 from "../sections/caseStudies/Tribetic/TribeticSection4";
import TribeticSection5 from "../sections/caseStudies/Tribetic/TribeticSection5";
import TribeticSection6 from "../sections/caseStudies/Tribetic/TribeticSection6";
import TribeticSection7 from "../sections/caseStudies/Tribetic/TribeticSection7";
import TribeticSection8 from "../sections/caseStudies/Tribetic/TribeticSection8";
import TribeticSection9 from "../sections/caseStudies/Tribetic/TribeticSection9";
import TribeticSection10 from "../sections/caseStudies/Tribetic/TribeticSection10";
import TestimonialSection from "../sections/TestimonialSection";



const Tribetic = ({data}) => {
    const mainData = data.allWpPage.nodes[0].caseStudyTribeticPageACF;
    const sectionRef = useRef();
    const [bgTransition, setBgTransition] = useState('');
    // useEffect(() => {
    //   let unmounted = false;
    //   gsap.registerPlugin(ScrollTrigger)
    //   setTimeout(() => {
    //     if (!unmounted) {
    //       gsap.set(sectionRef.current, {
    //         scrollTrigger: {
    //           trigger: '.tribetic-case-study .section-3 .steps',
    //           start: "top 60%",
    //           end: "bottom 60%",
    //           ease: "ease",
    //           scrub: true,
    //           markers: false,
    //           onEnter: () => setBgTransition('tribetic-bg'),
    //           onLeave: () => setBgTransition(''),
    //           onEnterBack: () => setBgTransition('tribetic-bg'),
    //           onLeaveBack: () => setBgTransition(''),
    //         }
    //       });
    //     }
    //   }, 1000);
  
    //   return () => {
    //     unmounted = true;
    //   }
    // }, []);
    return(
        <Layout headerClass="white-header">
            <Seo title="Tribetic" />
            <div className={"tribetic-case-study page-wrapper " + bgTransition} id="tribetic-casestudy-page-wrapper" ref={sectionRef}>
                <TribeticHeroSection
                    preTitle={parse(mainData.tribeticHeroSectionPretitle)}
                    mainTitle={parse(mainData.tribeticHeroSectionTitle)}
                    image1x={
                      {
                        sourceUrl: mainData.tribeticHeroSectionImage1x.sourceUrl,
                        altText: mainData.tribeticHeroSectionImage1x.altText,
                        width: mainData.tribeticHeroSectionImage1x.width,
                      }
                    }
                    image2x={
                      {
                        sourceUrl: mainData.tribeticHeroSectionImage2x.sourceUrl,
                        altText: mainData.tribeticHeroSectionImage2x.altText,
                        width: mainData.tribeticHeroSectionImage2x.width,
                      }
                    }
                />
                <ChallengesSection
                  companyInfoList={mainData.tribeticChallengeSectionCompanyInfoList}
                  challengesList={mainData.tribeticChallengeSectionChallengesList}
                />
                <TribeticSection1 
                    mainTitle={parse(mainData.tribeticSection1Title)}
                    mainDescription={parse(mainData.tribeticSection1Description)}
                    image1x={
                      {
                        sourceUrl: mainData.tribeticSection1Image1x.sourceUrl,
                        altText: mainData.tribeticSection1Image1x.altText,
                        width: mainData.tribeticSection1Image1x.width,
                      }
                    }
                    image2x={
                      {
                        sourceUrl: mainData.tribeticSection1Image2x.sourceUrl,
                        altText: mainData.tribeticSection1Image2x.altText,
                        width: mainData.tribeticSection1Image2x.width,
                      }
                    }
                />
                <TribeticSection2 
                    mainTitle={parse(mainData.tribeticSection2Title)}
                    mainDescription={parse(mainData.tribeticSection2Description)}
                    image1x={
                      {
                        sourceUrl: mainData.tribeticSection2Image1x.sourceUrl,
                        altText: mainData.tribeticSection2Image1x.altText,
                        width: mainData.tribeticSection2Image1x.width,
                      }
                    }
                    image2x={
                      {
                        sourceUrl: mainData.tribeticSection2Image2x.sourceUrl,
                        altText: mainData.tribeticSection2Image2x.altText,
                        width: mainData.tribeticSection2Image2x.width,
                      }
                    }
                />
                <TribeticSection3 
                    mainTitle={parse(mainData.tribeticSection3Title)}
                    mainDescription={parse(mainData.tribeticSection3Description)}
                    stepList={mainData.tribecticSection3Steps}
                />
                <TribeticSection4 
                  image1x={
                      {
                        sourceUrl: mainData.tribeticSection4Image1x.sourceUrl,
                        altText: mainData.tribeticSection4Image1x.altText,
                        width: mainData.tribeticSection4Image1x.width,
                      }
                  }
                  image2x={
                    {
                      sourceUrl: mainData.tribeticSection4Image2x.sourceUrl,
                      altText: mainData.tribeticSection4Image2x.altText,
                      width: mainData.tribeticSection4Image2x.width,
                    }
                  }
                />
                <TribeticSection5 
                  mainTitle={parse(mainData.tribeticSection5Title)}
                  mainDescription={parse(mainData.tribeticSection5Description)}
                  image1x={
                    {
                      sourceUrl: mainData.tribeticSection5Image1x.sourceUrl,
                      altText: mainData.tribeticSection5Image1x.altText,
                      width: mainData.tribeticSection5Image1x.width,
                    }
                  }
                  image2x={
                    {
                      sourceUrl: mainData.tribeticSection5Image2x.sourceUrl,
                      altText: mainData.tribeticSection5Image2x.altText,
                      width: mainData.tribeticSection5Image2x.width,
                    }
                  }
                />
                <TribeticSection6 
                  mainTitle={parse(mainData.tribeticSection6Title)}
                  mainDescription={parse(mainData.tribeticSection6Description)}
                  VideoUrl={mainData.tribeticSection6Videourl}
                  image1x={
                    {
                      sourceUrl: mainData.tribeticSection6Image1x.sourceUrl,
                      altText: mainData.tribeticSection6Image1x.altText,
                      width: mainData.tribeticSection6Image1x.width,
                    }
                  }
                  image2x={
                    {
                      sourceUrl: mainData.tribeticSection6Image2x.sourceUrl,
                      altText: mainData.tribeticSection6Image2x.altText,
                      width: mainData.tribeticSection6Image2x.width,
                    }
                  }
                />
                <TribeticSection7 
                  mainTitle={parse(mainData.tribeticSection7Title)}
                  mainDescription={parse(mainData.tribeticSection7Description)}
                  VideoUrl={mainData.tribeticSection7Videourl}
                  image1x={
                    {
                      sourceUrl: mainData.tribeticSection7Image1x.sourceUrl,
                      altText: mainData.tribeticSection7Image1x.altText,
                      width: mainData.tribeticSection7Image1x.width,
                    }
                  }
                  image2x={
                    {
                      sourceUrl: mainData.tribeticSection7Image2x.sourceUrl,
                      altText: mainData.tribeticSection7Image2x.altText,
                      width: mainData.tribeticSection7Image2x.width,
                    }
                  }
                />
                <TribeticSection8
                  mainTitle={parse(mainData.tribeticSection8Title)}
                  mainDescription={parse(mainData.tribeticSection8Description)}
                  image1x={
                    {
                      sourceUrl: mainData.tribeticSection8Image1x.sourceUrl,
                      altText: mainData.tribeticSection8Image1x.altText,
                      width: mainData.tribeticSection8Image1x.width,
                    }
                  }
                  image2x={
                    {
                      sourceUrl: mainData.tribeticSection8Image2x.sourceUrl,
                      altText: mainData.tribeticSection8Image2x.altText,
                      width: mainData.tribeticSection8Image2x.width,
                    }
                  }
                />
                <TribeticSection9 
                  mainTitle={parse(mainData.tribeticSection9Title)}
                  mainDescription={parse(mainData.tribeticSection9Description)}
                  image1x={
                    {
                      sourceUrl: mainData.tribeticSection9Image1x.sourceUrl,
                      altText: mainData.tribeticSection9Image1x.altText,
                      width: mainData.tribeticSection9Image1x.width,
                    }
                  }
                  image2x={
                    {
                      sourceUrl: mainData.tribeticSection9Image2x.sourceUrl,
                      altText: mainData.tribeticSection9Image2x.altText,
                      width: mainData.tribeticSection9Image2x.width,
                    }
                  }
                />
                <TribeticSection10 
                  mainTitle={parse(mainData.tribeticSection10Title)}
                  mainDescription={parse(mainData.tribeticSection10Description)}
                  image1x={
                    {
                      sourceUrl: mainData.tribeticSection10Image1x.sourceUrl,
                      altText: mainData.tribeticSection10Image1x.altText,
                      width: mainData.tribeticSection10Image1x.width,
                    }
                  }
                  image2x={
                    {
                      sourceUrl: mainData.tribeticSection10Image2x.sourceUrl,
                      altText: mainData.tribeticSection10Image2x.altText,
                      width: mainData.tribeticSection10Image2x.width,
                    }
                  }
                />
                {/* <TestimonialSection
                  mainTitle={parse(mainData.tribeticTesimonialSectionTitle)}
                  message={parse(mainData.tribeticTesimonialSectionDescription)}
                  name={parse(mainData.tribeticTesimonialSectionName)}
                  designation={parse(mainData.tribeticTesimonialSectionDesignation)}
                  headshot1x={
                    {
                      sourceUrl: mainData.tribeticTesimonialSectionHeadshot1x.sourceUrl,
                      altText: mainData.tribeticTesimonialSectionHeadshot1x.altText,
                      width: mainData.tribeticTesimonialSectionHeadshot1x.width,
                    }
                  }
                  headshot2x={
                    {
                      sourceUrl: mainData.tribeticTesimonialSectionHeadshot2x.sourceUrl,
                      altText: mainData.tribeticTesimonialSectionHeadshot2x.altText,
                      width: mainData.tribeticTesimonialSectionHeadshot2x.width,
                    }
                  }
                  logo={
                    {
                      sourceUrl: mainData.tribeticTesimonialSectionLogo.sourceUrl,
                      altText: mainData.tribeticTesimonialSectionLogo.altText,
                      width: mainData.tribeticTesimonialSectionLogo.width,
                    }
                  }
                /> */}
            </div>
        </Layout>
    )
}

export const TribeticQuery = graphql`
{
    allWpPage(filter: {slug: {eq: "tribetic"}}) {
        nodes {
            caseStudyTribeticPageACF {
                tribeticHeroSectionPretitle
                tribeticHeroSectionTitle
                tribeticHeroSectionImage1x {
                  altText
                  sourceUrl
                  width
                }
                tribeticHeroSectionImage2x {
                  altText
                  sourceUrl
                  width
                }
                tribeticChallengeSectionChallengesList {
                  title
                  description
                }
                tribeticChallengeSectionCompanyInfoList {
                  title
                  description
                }
                tribeticSection1Title
                tribeticSection1Description
                tribeticSection1Image1x {
                  altText
                  sourceUrl
                  width
                }
                tribeticSection1Image2x {
                  altText
                  sourceUrl
                  width
                }
                tribeticSection2Title
                tribeticSection2Description
                tribeticSection2Image1x {
                  altText
                  sourceUrl
                  width
                }
                tribeticSection2Image2x {
                  altText
                  sourceUrl
                  width
                }
                tribeticSection3Title
                tribeticSection3Description
                tribecticSection3Steps {
                  title
                  image1x {
                    altText
                    sourceUrl
                    width
                  }
                  image2x {
                    altText
                    sourceUrl
                    width
                  }
                }
                tribeticSection4Image1x {
                  altText
                  sourceUrl
                  width
                }
                tribeticSection4Image2x {
                  altText
                  sourceUrl
                  width
                }
                tribeticSection5Title
                tribeticSection5Description
                tribeticSection5Image1x {
                  altText
                  sourceUrl
                  width
                }
                tribeticSection5Image2x {
                  altText
                  sourceUrl
                  width
                }
                tribeticSection6Title
                tribeticSection6Description
                tribeticSection6Videourl
                tribeticSection6Image1x {
                  altText
                  sourceUrl
                  width
                }
                tribeticSection6Image2x {
                  altText
                  sourceUrl
                  width
                }
                tribeticSection7Title
                tribeticSection7Description
                tribeticSection7Videourl
                tribeticSection7Image1x {
                  altText
                  sourceUrl
                  width
                }
                tribeticSection7Image2x {
                  altText
                  sourceUrl
                  width
                }

                tribeticSection8Title
                tribeticSection8Description
                tribeticSection8Image1x {
                  altText
                  sourceUrl
                  width
                }
                tribeticSection8Image2x {
                  altText
                  sourceUrl
                  width
                }
                tribeticSection9Title
                tribeticSection9Description
                tribeticSection9Image1x {
                  altText
                  sourceUrl
                  width
                }
                tribeticSection9Image2x {
                  altText
                  sourceUrl
                  width
                }
                tribeticSection10Title
                tribeticSection10Description
                tribeticSection10Image1x {
                  altText
                  sourceUrl
                  width
                }
                tribeticSection10Image2x {
                  altText
                  sourceUrl
                  width
                }
                tribeticTesimonialSectionTitle
                tribeticTesimonialSectionDescription
                tribeticTesimonialSectionHeadshot1x {
                  altText
                  sourceUrl
                  width
                }
                tribeticTesimonialSectionHeadshot2x {
                  altText
                  sourceUrl
                  width
                }
                tribeticTesimonialSectionName
                tribeticTesimonialSectionDesignation
                tribeticTesimonialSectionLogo {
                  altText
                  sourceUrl
                  width
                }
            }
        }
    }
}
`
export default Tribetic;
